$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-menu-arrow: unquote('"\\e91f"');
$icon-click-arrow: unquote('"\\e91e"');
$icon-close: unquote('"\\e919"');
$icon-check: unquote('"\\e91d"');
$icon-padlock: unquote('"\\e91c"');
$icon-warning: unquote('"\\e91b"');
$icon-urban-analytics: unquote('"\\e91a"');
$icon-reset: unquote('"\\e918"');
$icon-multi-family: unquote('"\\e917"');
$icon-retail: unquote('"\\e900"');
$icon-office: unquote('"\\e901"');
$icon-community: unquote('"\\e902"');
$icon-zoning_data: unquote('"\\e90f"');
$icon-zoning_tabulation: unquote('"\\e910"');
$icon-zoning_analysis: unquote('"\\e911"');
$icon-hotel: unquote('"\\e912"');
$icon-lab: unquote('"\\e913"');
$icon-amusement: unquote('"\\e914"');
$icon-manufacturing: unquote('"\\e915"');
$icon-single-family: unquote('"\\e916"');
$icon-left-arrow: unquote('"\\e90e"');
$icon-search: unquote('"\\e90d"');
$icon-arrow_down: unquote('"\\e90b"');
$icon-info: unquote('"\\e90c"');
$icon-user: unquote('"\\e90a"');
$icon-save: unquote('"\\e903"');
$icon-print: unquote('"\\e904"');
$icon-folder: unquote('"\\e905"');
$icon-settings: unquote('"\\e906"');
$icon-address_search: unquote('"\\e907"');
$icon-advanced_search: unquote('"\\e908"');
$icon-layers: unquote('"\\e909"');

