@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?2zz5fx');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?2zz5fx#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?2zz5fx') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?2zz5fx') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?2zz5fx##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu-arrow {
  &:before {
    content: $icon-menu-arrow; 
  }
}
.icon-click-arrow {
  &:before {
    content: $icon-click-arrow; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-padlock {
  &:before {
    content: $icon-padlock; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-urban-analytics {
  &:before {
    content: $icon-urban-analytics; 
  }
}
.icon-reset {
  &:before {
    content: $icon-reset; 
  }
}
.icon-multi-family {
  &:before {
    content: $icon-multi-family; 
  }
}
.icon-retail {
  &:before {
    content: $icon-retail; 
  }
}
.icon-office {
  &:before {
    content: $icon-office; 
  }
}
.icon-community {
  &:before {
    content: $icon-community; 
  }
}
.icon-zoning_data {
  &:before {
    content: $icon-zoning_data; 
  }
}
.icon-zoning_tabulation {
  &:before {
    content: $icon-zoning_tabulation; 
  }
}
.icon-zoning_analysis {
  &:before {
    content: $icon-zoning_analysis; 
  }
}
.icon-hotel {
  &:before {
    content: $icon-hotel; 
  }
}
.icon-lab {
  &:before {
    content: $icon-lab; 
  }
}
.icon-amusement {
  &:before {
    content: $icon-amusement; 
  }
}
.icon-manufacturing {
  &:before {
    content: $icon-manufacturing; 
  }
}
.icon-single-family {
  &:before {
    content: $icon-single-family; 
  }
}
.icon-left-arrow {
  &:before {
    content: $icon-left-arrow; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-arrow_down {
  &:before {
    content: $icon-arrow_down; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-folder {
  &:before {
    content: $icon-folder; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-address_search {
  &:before {
    content: $icon-address_search; 
  }
}
.icon-advanced_search {
  &:before {
    content: $icon-advanced_search; 
  }
}
.icon-layers {
  &:before {
    content: $icon-layers; 
  }
}

