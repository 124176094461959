/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
@import "./assets/styles/variables";
@import "./assets/styles/fonts";
@import "./assets/icons/style";
@import "./assets/styles/mixins";
@import "~bootstrap/dist/css/bootstrap.min.css";

* {
  box-sizing: border-box;
}

body {
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: $font-family !important;
  font-size: 0.875rem;
  background-color: map-get($colors, "background");
  overflow: hidden;
  min-height: 100vh;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: map-get($colors, primary);
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 #474242;
  caret-color: map-get($colors, primary);
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: revert !important;
  font-size: 0.875rem !important;
  line-height: normal !important;
  font-family: $font-family !important;
}

button {
  font-family: $font-family !important;
  line-height: normal !important;
  font-size: 0.875rem !important;
}

h1 {
  display: flex;
  align-items: baseline;
  line-height: 0;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.mr-2 {
  margin-right: 10px;
}

.mb-2 {
  margin-bottom: 10px;
}

.pulse {
  overflow: hidden;
  margin-top: 0;
}

.pulse::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  animation: pulse 1s infinite;
  background: linear-gradient(to right, transparent, #d9d9d9, transparent);
}

@keyframes pulse {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: map-get($colors, primary);
  border-radius: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: map-get($colors, secondary);
}

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

//ngx-controls

.ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: none;
  width: 0;
}
.ngx-slider {
  margin: 18px 0 15px !important;
  .ngx-slider-pointer {
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    top: -14px !important;
    background-color: map-get($colors, primary) !important;
    z-index: 3;
    border-radius: 16px;
    &::after {
      content: "";
      width: 6px !important;
      height: 6px !important;
      position: absolute;
      top: 2px !important;
      left: 2px !important;
      border-radius: 4px;
      background: map-get($colors, primary) !important;
      display: none;
    }
  }
}
.ngx-slider[disabled] {
  cursor: not-allowed;
  .ngx-slider-pointer {
    cursor: pointer;
    background-color: map-get($colors, gray) !important;
    &::after {
      display: none;
    }
  }
}
.ngx-slider .ngx-slider-tick {
  width: 0 !important;
}

.ngx-slider.animate .ngx-slider-bubble {
  color: white !important;
  top: 6px !important;
  display: none !important;
}

.ngx-slider .ngx-slider-bubble {
  display: none !important;
}

.ngx-slider .ngx-slider-bubble {
  color: white !important;
  top: 6px !important;
}

ngx-slider .ngx-slider-selection {
  z-index: 2;
  background: map-get($colors, gray) !important;
  border-radius: 2px;
}

.ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
  background: map-get($colors, primary) !important;
  border: none !important;
}

.ngx-slider .ngx-slider-bubble {
  padding: 1px 6px !important;
  color: map-get($colors, black);
  font-size: 10px !important;
}

.ngx-slider .ngx-slider-bar-wrapper {
  padding-top: 10px !important;
}

.mapboxgl-ctrl-geocoder--button {
  &:hover {
    background-color: transparent !important;
  }
}

.mapboxgl-ctrl-geocoder--input {
  display: block;
  width: 86%;
  height: 30px;
  padding: 0 0.5rem;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: map-get($colors, darkMode) !important;
  background-clip: padding-box;
  border-radius: 30%;
  border: 0;
  outline: none;
  box-shadow: inset 0 -1px 0 #fff;
  background-color: #ffffff;
  z-index: 2;
}

.mapboxgl-ctrl-geocoder--icon .mapboxgl-ctrl-geocoder--icon-search {
  display: none;
}

.mapboxgl-ctrl-geocoder--button {
  display: none;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
  font-weight: bold;
}

.mapboxgl-ctrl-geocoder--icon-loading {
  display: none !important;
}

.mapboxgl-ctrl-geocoder--icon {
  height: 16px;
}

.mapboxgl-ctrl-geocoder--icon-search {
  display: none !important;
}

.mapboxgl-ctrl-geocoder--pin-right {
  position: absolute;
  right: 20px;
  top: 38%;
  transform: translateY(-50%);
}

#geocoder {
  width: 100%;
}

.mapboxgl-ctrl-geocoder--button {
  position: relative;
  border: 0;
  background-color: transparent;
  top: 2px;
}

.mapboxgl-ctrl-group {
  position: absolute;
  right: 0px;
  bottom: 60px;
}

.suggestions-wrapper {
  position: fixed;
  width: calc(318px - 10px);
  background: white;
  top: 34px;
  left: -4px;
  z-index: 100000;
  list-style: none;
  border-radius: 0.5rem;
  .suggestions {
    margin-bottom: 0;
    list-style: none;
    padding: 0.5rem 1rem;
    color: #757575;

    li {
      padding: 0.325rem 0.375rem;
      font-size: 0.675rem;
      cursor: pointer;
      border-radius: 0.5rem;
      &:hover {
        background-color: map-get($colors, light-gray);
      }
      a {
        .mapboxgl-ctrl-geocoder--suggestion {
          width: auto;
          // display: flex;
          // align-items: center;
          // justify-content: flex-start;
        }
      }
    }
    .mapboxgl-ctrl-geocoder--powered-by {
      display: none !important;
    }
  }
}
.apexcharts-legend-series {
  display: none;
}

.apexcharts-menu-icon {
  svg {
    fill: white !important;
  }
}

.apexcharts-canvas {
  left: -12px !important;
}

.mapboxgl-ctrl-geocoder--icon {
  fill: map-get($colors, darkMode) !important;
}

// zd tooltips styles

.zd_tooltip_warning .tooltip-arrow {
  position: relative;
  left: 10px;
}

.zd_tooltip_floorAreaInfo_Data .tooltip-arrow {
  position: relative;
  left: 10px;
}

.zd_tooltip_floorAreaInfo_Data .tooltip-arrow::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2c2828;
  opacity: 1;
}

.zd_tooltip_floorAreaInfo_Data .tooltip-inner {
  max-width: 466px;
  height: 325px !important;
}

.zd_tooltip_warning .tooltip-arrow::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2c2828;
  opacity: 1;
}
.zd_tooltip_warning .tooltip-inner {
  max-width: 280px;
  position: relative;
  left: 16px;
  font-size: 0.6875rem;
  text-align: left;
  background-color: map-get($colors, darkMode);
  box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.8);
}

.zoning_analysis a {
  color: map-get($colors, primary) !important;
  font-weight: 600;
}

.manage_layer_tooltip .tooltip-arrow {
  position: relative;
  left: 9px;
}

.manage_layer_tooltip .tooltip-arrow::before {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #2c2828;
  opacity: 1;
}

.tooltip-inner {
  position: relative;
  left: 16px;
  font-size: 0.6875rem;
  text-align: left;
  background-color: map-get($colors, darkMode);
  border-radius: 8px;
  box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.8);
  z-index: 100000;
}

.tooltip.show {
  opacity: 1 !important ;
}

.zd_tooltip_floor_area_info {
  position: relative;
  left: 16px;
}

.zd_tooltip_floor_area_info .tooltip-arrow {
  position: relative;
  left: 10px;
}

.zd_tooltip_floor_area_info .tooltip-arrow::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2c2828;
  opacity: 1;
}

.zd_tooltip_floor_area_info .tooltip-inner {
  max-width: 450px;
}

.zd_tooltip_financial_Data .tooltip-arrow {
  position: relative;
  left: 10px;
}

.zd_tooltip_financial_Data .tooltip-arrow::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2c2828;
  opacity: 1;
}

.zd_tooltip_financial_Data .tooltip-inner {
  max-width: 450px;
}

.zd_tooltip_building_gross_Data .tooltip-arrow {
  position: relative;
  left: 10px;
}

.zd_tooltip_building_gross_Data .tooltip-arrow::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2c2828;
  opacity: 1;
}

.zd_tooltip_building_gross_Data .tooltip-inner {
  max-height: 140px;
  height: 100%;
  max-width: 580px;
}

.zd_tooltip_tax_financial .tooltip-arrow {
  position: relative;
  left: 10px;
}

.zd_tooltip_tax_financial .tooltip-arrow::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2c2828;
  opacity: 1;
}

.zd_tooltip_tax_financial .tooltip-inner {
  max-width: 500px;
}

.manage_layer_data_acording_layer .tooltip-arrow {
  position: relative;
  left: 10px;
}

.manage_layer_data_acording_layer .tooltip-arrow::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2c2828;
  opacity: 1;
}

.manage_layer_data_acording_layer .tooltip-inner {
  max-width: 640px;
  font-size: 0.6875rem;
}

.manage_layer_data_acording_layer--ihda .tooltip-inner {
  max-width: 600px;
  font-size: 0.6875rem;
}

.manage_layer_data_acording_layer--ihda .tooltip-arrow {
  position: relative;
  left: 10px;
}

.manage_layer_data_acording_layer--ihda .tooltip-arrow::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2c2828;
  opacity: 1;
}

.za_select_use_data .tooltip-arrow {
  position: relative;
  left: 13px;
}

.za_select_use_data .tooltip-arrow::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2c2828;
  opacity: 1;
}

.za_select_use_data .tooltip-inner {
  max-width: 700px;
  font-size: 0.6875rem;
  position: relative;
  left: 20px;
  top: 116px;
}

.za_select_bulk_tooltip_data .tooltip-arrow {
  position: relative;
  left: 13px;
}

.za_select_bulk_tooltip_data .tooltip-arrow::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2c2828;
  opacity: 1;
}

.za_select_bulk_tooltip_data .tooltip-inner {
  max-width: 940px;
  width: 432px;
  font-size: 0.6875rem;
  position: relative;
  left: 20px;
  top: 116px;
}

.za_select_yards_tooltip_data .tooltip-arrow {
  position: relative;
  left: 13px;
  z-index: 1332323;
}

.za_select_yards_tooltip_data .tooltip-arrow::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2c2828;
  opacity: 1;
}

.za_select_yards_tooltip_data .tooltip-inner {
  max-width: 940px;
  width: 524px;
  font-size: 0.6875rem;
  position: relative;
  left: 20px;
  top: 56px;
}

.za_select_subYard_tooltip_data .tooltip-arrow {
  position: relative;
  left: 13px;
  z-index: 1332323;
}

.za_select_subYard_tooltip_data .tooltip-arrow::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2c2828;
  opacity: 1;
}

.za_select_subYard_tooltip_data .tooltip-inner {
  max-width: 940px;
  width: 230px;
  padding: 0.875rem;
  font-size: 0.6875rem;
  position: relative;
  left: 20px;
  top: 0px;
}

.za_select_visualize_tooltip_data .tooltip-arrow {
  position: relative;
  left: 13px;
  z-index: 1332323;
}

.za_select_visualize_tooltip_data .tooltip-arrow::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2c2828;
  opacity: 1;
}

.za_select_visualize_tooltip_data .tooltip-inner {
  max-width: 940px;
  width: 208px;
  padding: 0.875rem;
  font-size: 0.6875rem;
  position: relative;
  left: 20px;
  top: 0px;
}

.ngx-slider.animate .ngx-slider-bar-wrapper {
  transition: none !important;
}

.slider-animation {
  animation: none !important;
}

.ngx-slider.animate .ngx-slider-pointer {
  transition: none !important;
}

// =======================  VIEW EXPORT STYLES  ==========================

// @font-face {
//   font-family: "icomoon";
//   src: url("assets/fonts/icomoon.eot?gwxey5");
//   src: url("assets/fonts/icomoon.eot?gwxey5#iefix") format("embedded-opentype"),
//     url("assets/fonts/icomoon.ttf?gwxey5") format("truetype"),
//     url("assets/fonts/icomoon.woff?gwxey5") format("woff"),
//     url("assets/fonts/icomoon.svg?gwxey5#icomoon") format("svg");
//   font-weight: 400;
//   font-style: normal;
//   font-display: block;
// }

// @font-face {
//   font-family: Gotham;
//   src: url("assets/fonts/gotham-thin-webfont.woff2"),
//     url("assets/fonts/gotham-thin-webfont.woff");
//   font-weight: 100;
// }
// @font-face {
//   font-family: Gotham;
//   src: url("assets/fonts/gotham-xlight-webfont.woff2"),
//     url("assets/fonts/gotham-xlight-webfont.woff");
//   font-weight: 200;
// }
// @font-face {
//   font-family: Gotham;
//   src: url("assets/fonts/gotham-light-webfont.woff2") format("truetype"),
//     url("assets/fonts/gotham-light-webfont.woff") format("truetype");
//   font-weight: 400;
// }
// @font-face {
//   font-family: Gotham;
//   src: url("assets/fonts/gotham-medium-webfont.woff2") format("truetype"),
//     url("assets/fonts/gotham-medium-webfont.woff") format("truetype");
//   font-weight: 500;
// }
// @font-face {
//   font-family: Gotham;
//   src: url("assets/fonts/gotham-bold-webfont.woff2"),
//     url("assets/fonts/gotham-bold-webfont.woff");
//   font-weight: 600;
// }
// @font-face {
//   font-family: Gotham;
//   src: url("assets/fonts/gotham-black-webfont.woff2"),
//     url("assets/fonts/gotham-black-webfont.woff");
//   font-weight: 700;
// }

.modal-use-table {
  max-width: 100%;
  margin: 0 auto;
  display: flex !important;
  align-items: center;
  justify-content: center;

  .modal-dialog {
    width: auto;
    max-width: 100%;
  }

  .modal-content {
    width: auto;
    max-width: 100%;
    background-color: transparent;
  }
}

.modal-streetview {
  height: 350px !important;
  bottom: 123px;
  left: auto;
  right: 81px;
  overflow: hidden;
  position: fixed;
  top: auto;
  width: 500px !important;
  border-radius: 0 !important;
  z-index: 995;
}

.hidden-important {
  display: none !important;
}

.modal-body #scene-container canvas {
  border-radius: 10px;
  border: 1px solid #fff;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cross:before {
  content: "\ea0f";
}

.icon-arrow-up2:before {
  content: "\ea3a";
}

.icon-arrow-down2:before {
  content: "\ea3e";
}

.icon-arrow-left2:before {
  content: "\ea40";
}

.active {
  border: 4px solid blue;
}
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  background: black;
}
#map {
  width: 100%;
  height: 100%;
}

.btn-custom {
  background: #f0d459;
}

.lil-gui {
  --background-color: #2c2828;
  --number-color: #f0d459 !important;
  --title-text-color: #f0d459 !important;
}

.lil-gui * {
  font-size: 11px !important;
}

.lil-gui.transition > .children {
  transition-duration: 0s;
  transition-property: none;
  transition-timing-function: unset;
  pointer-events: auto;
}

.lil-gui.closed > .children {
  transform: none;
}

.lil-gui.root > .children > .lil-gui.closed > .title {
  margin: 0 10px;
}

.lil-gui.root {
  background: none;
}

.lil-gui.root > .title {
  display: none;
}

.lil-gui.allow-touch-styles {
  margin-bottom: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 10px;
}

.lil-gui.allow-touch-styles.closed {
  padding-bottom: 0 !important;
}

.lil-gui .title:before {
  font-family: "icomoon" !important;
}

.lil-gui.allow-touch-styles .title {
  color: #f0d459;
  text-decoration: none !important;
  border-top: none !important;
  border-bottom: 1px solid #fff !important;
  margin: 0 10px 10px;
  padding: 0;

  /* use !important to prevent issues with browser extensions that change fonts */
  /* font-family: 'icomoon' !important; */
  font-style: normal;
  font-weight: 500;
  font-variant: normal;
  font-size: 0.75rem;
  text-transform: capitalize;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lil-gui.allow-touch-styles.closed .title {
  border-bottom: none !important;
  position: relative;
}

.lil-gui.allow-touch-styles .title:before {
  /* content: '\0279c'; */
  content: "\e90e";
  transform: rotate(90deg);
  position: absolute;
  right: 1.8em;
  color: #fff;
}

.lil-gui.allow-touch-styles.closed .title:before {
  transform: rotate(270deg);
  top: -2px;
  right: 0;
}

.lil-gui.allow-touch-styles .title:hover {
  text-decoration: none !important;
  background: none !important;
}

.lil-gui .controller {
  padding: 0 10px;
  font-size: 0.725rem !important;
}

.lil-gui .controller > .name {
  text-transform: capitalize;
  font-size: 11px !important;
  font-weight: 400;
}

.lil-gui input {
  color: var(--number-color);
}

.lil-gui input[type="checkbox"] {
  border: 1px solid #f0d459;
}

.lil-gui input[type="checkbox"]:checked {
  color: #2c2828;
  background: #f0d459;
}

.lil-gui input[type="checkbox"]:checked:before {
  font-size: 11px !important;
}

.lil-gui input[type="checkbox"]:focus {
  box-shadow: none !important;
}

.lil-gui input[type="number"],
.lil-gui input[type="text"] {
  font-size: 11px !important;
}

.lil-gui .title:before {
  font-family: "icomoon" !important;
}

.modal-dialog {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.modal-content {
  width: auto !important;
}
