@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Font/gotham-thin-webfont.woff2"),
    url("../fonts/Gotham-Font/gotham-thin-webfont.woff");
  font-weight: 100;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Font/gotham-xlight-webfont.woff2"),
    url("../fonts/Gotham-Font/gotham-xlight-webfont.woff");
  font-weight: 200;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Font/gotham-light-webfont.woff2") format("truetype"),
    url("../fonts/Gotham-Font/gotham-light-webfont.woff") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Font/gotham-medium-webfont.woff2")
      format("truetype"),
    url("../fonts/Gotham-Font/gotham-medium-webfont.woff") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Font/gotham-bold-webfont.woff2"),
    url("../fonts/Gotham-Font/gotham-bold-webfont.woff");
  font-weight: 600;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Font/gotham-black-webfont.woff2"),
    url("../fonts/Gotham-Font/gotham-black-webfont.woff");
  font-weight: 700;
}
